<template>
  <div class="companies">
    <MobileTable
      v-model:selection="store.state.contract.selectedContracts"
      :label="$route.name"
      :columns="contract.columns"
      :data="store.state.contract.contracts"
    />
    <DesktopTable
      v-model:selection="store.state.contract.selectedContracts"
      :label="$route.name"
      :columns="contract.columns"
      :data="store.state.contract.contracts"
    />
    <Actions :items="contract.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions, aws } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/contratos/nuevo");
    };
    const onDownload = async () => {
      if (store.state.contract.selectedContracts.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        const documents = store.state.contract.selectedContracts[0].documents;
        documents.forEach((doc) =>
          aws.downloadFile(
            `${doc.name}${doc.ext}`,
            `${doc.path}${doc.name}${doc.ext}`,
            () => {}
          )
        );
      }
    };
    const onChangeStatus = async () => {
      if (!store.state.contract.selectedContracts.length) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);

        const contracts = store.state.contract.selectedContracts.map(
          (item) => item.id
        );

        const response = await store.dispatch(actions.contractActions.status, {
          contracts,
        });

        if (response.ok) {
          store.commit("setSelectedContracts", []);
          await store.dispatch(
            actions.contractActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const contract = reactive({
      columns: [
        {
          field: "personnel",
          header: "Nombre del Personal",
          sort: true,
        },
        {
          field: "updatedAt",
          header: "Ult. Fecha de carga",
          sort: true,
          date: true,
        },
        {
          field: "updatedUser",
          header: "Cargado por Usuario",
          sort: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/contratos/nuevo"),
        },
        {
          name: "Descargar",
          action: onDownload,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/contratos/listado"),
        },
        {
          name: "Vencido",
          action: () => onChangeStatus(),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/contratos/listado"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedContracts", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.contractActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, contract };
  },
};
</script>

<style scoped></style>
